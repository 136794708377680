exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ats-js": () => import("./../../../src/pages/ats.js" /* webpackChunkName: "component---src-pages-ats-js" */),
  "component---src-pages-carbon-neutrality-js": () => import("./../../../src/pages/carbon-neutrality.js" /* webpackChunkName: "component---src-pages-carbon-neutrality-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-campus-js": () => import("./../../../src/pages/digital-campus.js" /* webpackChunkName: "component---src-pages-digital-campus-js" */),
  "component---src-pages-headhunting-js": () => import("./../../../src/pages/headhunting.js" /* webpackChunkName: "component---src-pages-headhunting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pre-screening-js": () => import("./../../../src/pages/pre-screening.js" /* webpackChunkName: "component---src-pages-pre-screening-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-usability-js": () => import("./../../../src/pages/usability.js" /* webpackChunkName: "component---src-pages-usability-js" */)
}

